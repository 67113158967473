import React, { useEffect, useState } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import CardProduct from "../components/CardProduct"

const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Productos = ({ name = 'productos' }) => {
    let { cat } = useParams()
    let { pathname } = useLocation()
    let navigate =  useNavigate()
    let query = useQuery()

    const [productos, setProductos] = useState([])
    const [categoria, setCategoria] = useState('')
    const [categorias, setCategorias] = useState([])
    const productosHTML = []
    const categoriasHTML = []

    useEffect(() => {
        obtenerProductos()
        obtenerCategorias()
    }, [categoria])

    function obtenerProductos() {
        let url = "https://api.regalosplanetart.com.mx/Home/getArt"
        // let url = 'https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/getArt'
        
        let data = new FormData()
        data.append('key', 'testKey')
        data.append('filter', '')
        
        let xhr = new XMLHttpRequest()
        xhr.open('POST', url, true)
        xhr.onload = e => {
            const response = JSON.parse(e.target.responseText)
            const articulos = response.articulos

            // console.log(articulos)            
            
                articulos.forEach(articulo => {
                    let searchCategory = query.get("categoria")
                    
                    if ((articulo.modals).toLowerCase() === cat) {
                        let productCategory = articulo.cats
                        let categoriaJSON = productCategory.replaceAll('`', '"')
                        let finalCategory = JSON.parse(categoriaJSON)

                        if (searchCategory) {
                            if (searchCategory === finalCategory.categoria) {
                                productosHTML.push(<CardProduct key={articulo.id} title={articulo.nombre} desc={articulo.descripcion} id={articulo.id} image={articulo.imagen_1} />)
                            }
                        } else {
                            productosHTML.push(<CardProduct key={articulo.id} title={articulo.nombre} desc={articulo.descripcion} id={articulo.id} image={articulo.imagen_1} />)
                        }

                    }
                })

                setProductos(productosHTML)
            }
            xhr.send(data)
    }

    function obtenerCategorias() {
        let url = 'https://api.regalosplanetart.com.mx/Home/getCategorias'

        let consulta = new XMLHttpRequest()
            consulta.open('POST', url, true)
            consulta.onload = response => {
                let res = JSON.parse(response.target.responseText)
                let cats = res.inventory

                categoriasHTML.push(<option key="0-0" value="">Sin categoría</option>)
                cats.forEach(category => categoriasHTML.push(<option key={category.categoria_id} value={category.categoria_nombre}>{category.categoria_nombre}</option>))
                setCategorias(categoriasHTML)
            }
            consulta.send()
    }

    function actualizarCategoria() {
        // console.log('paso 1')
        let newCat = document.querySelector('#filterCategoria').value
        // console.log(newCat)
        navigate(`${pathname}?categoria=${newCat}`)
        setCategoria(newCat)
        // console.log(categoria)
        name += '1'
        // console.log(name)
    }

    return (
        <div className="full-page">
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="form-group" style={{maxWidth: '300px'}}>
                            <label htmlFor="filterCategoria" className="form-label">
                                Filtrar por categoría:
                            </label>
                            <select name="filterCategoria" id="filterCategoria" className="form-control" onChange={() => {actualizarCategoria()}}>
                                {categorias}
                            </select>
                        </div>
                    </div>
                    { productos }
                </div>
            </div>
        </div>
    );
}
 
export default Productos;