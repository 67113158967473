import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faShoppingCart, faSearch, fas } from "@fortawesome/free-solid-svg-icons"

// Imágenes
import Logo from './../assets/img/logo.png'
import { useEffect, useState } from "react"

const Navbar = () => {
    const [isLoged, setIsLoged] = useState(false)

    useEffect(() => {
        function getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        if (getCookie('userData'))
            setIsLoged(true)
        else
            setIsLoged(false)
    })

    function cerrarSesion() {
        setCookie('userData','', -1)
        setIsLoged(false)
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-green shadow-sm">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src={Logo} alt="Logo de Planet Art" className="img-fluid" />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item dropdown">
                            <button className="nav-link btn btn-link dropdown-toggle text-white fw-bold" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                Productos
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a className="dropdown-item fw-bold" href="/productos/mayoreo">
                                        Mayoreo
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item fw-bold" href="/productos/menudeo">
                                        Menudeo
                                    </a>
                                </li>
                            </ul>
                        </li>
                        { !isLoged ? 
                            <li className="nav-item">
                                <Link to="/iniciar-sesion" className="nav-link text-white fw-bold">
                                    Iniciar sesión
                                </Link>
                            </li> : 
                            ''
                        }
                        {
                            !isLoged ? 
                            <li className="nav-item">
                                <Link to="/registro" className="nav-link text-white fw-bold">
                                    Crea tu cuenta
                                </Link>
                            </li> : 
                            ''
                        }
                        {

                            isLoged ? 
                            <li className="nav-item dropdown">
                                <button className="nav-link btn btn-link dropdown-toggle text-white" id="sessionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    Mi Cuenta
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="sessionDropdown">
                                    <li><button className="dropdown-item btn-link" onClick={() => cerrarSesion()}>Cerrar sesión</button></li>
                                </ul>
                            </li> :
                            ''
                        }
                        <li className="nav-item">
                            <Link to="/carrito" className="nav-link text-white">
                                <FontAwesomeIcon icon={faShoppingCart} size="2x" />
                            </Link>
                        </li>
                        <li className="nav-item d-flex align-items-center ms-2">
                            <a href="https://api.whatsapp.com/send/?phone=3334968365&text=Quiero+m%C3%A1s+informaci%C3%B3n+de+los+productos+PlanetArt&app_absent=0" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-white">
                                <FontAwesomeIcon icon={faWhatsapp} size="2x" className="me-3" />
                            </a>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                            <a href="https://www.facebook.com/regalosplanetart/" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-white">
                                <FontAwesomeIcon icon={faFacebookF} size="2x" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
 
export default Navbar;