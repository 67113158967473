import { Link } from "react-router-dom"

import MayoreoBanner from "./../assets/img/mayoreo/MayoreoBanner.png"
import Cat01 from "./../assets/img/menudeo/Cat01.png"
import Cat02 from "./../assets/img/mayoreo/Cat02.png"
import Cat03 from "./../assets/img/mayoreo/Cat03.png"
import Cat04 from "./../assets/img/mayoreo/Cat04.png"
import Cat05 from "./../assets/img/mayoreo/Cat05.png"
import Cat06 from "./../assets/img/mayoreo/Cat06.png"

const Mayoreo = () => {
    return (
        <div>
            <div className="container-fluid p-0">
                <img src={MayoreoBanner} alt="Mayoreo" className="img-fluid" />
            </div>

            <div className="container my-5">
                <h2 className="text-center fw-bold mb-3">
                    ¡Los mejores precios y calidad para tu negocio!
                </h2>

                <div className="row">
                    <div className="col-6 col-md-4 p-3">
                        <Link to="/productos/mayoreo?categoria=amor y amistad" className="text-reset text-decoration-none">
                            <img src={Cat02} alt="Amor y amistad" className="img-fluid mb-2" />
                            <h5 className="text-center">
                                Amor y amistad
                            </h5>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 p-3">
                        <Link to="/productos/mayoreo?categoria=papa" className="text-reset text-decoration-none">
                            <img src={Cat03} alt="Papá" className="img-fluid mb-2" />
                            <h5 className="text-center">
                                Papá
                            </h5>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 p-3">
                        <Link to="/productos/mayoreo?categoria=mama" className="text-reset text-decoration-none">
                            <img src={Cat04} alt="Mamá" className="img-fluid mb-2" />
                            <h5 className="text-center">
                                Mamá
                            </h5>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 p-3">
                        <Link to="/productos/mayoreo?categoria=madera" className="text-reset text-decoration-none">
                            <img src={Cat05} alt="Madera" className="img-fluid mb-2" />
                            <h5 className="text-center">
                                Madera
                            </h5>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 p-3">
                        <Link to="/productos/mayoreo?categoria=cumpleaños" className="text-reset text-decoration-none">
                            <img src={Cat06} alt="Cumpleaños" className="img-fluid mb-2" />
                            <h5 className="text-center">
                                Cumpleaños
                            </h5>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 p-3">
                        <Link to="/productos/mayoreo?categoria=navidad" className="text-decoration-none text-reset">
                            <img src={Cat01} alt="Navidad" className="img-fluid mb-2" />
                            <h5 className="text-center">
                                Navidad
                            </h5>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Mayoreo;