import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Imágenes
import LogoPA from './../assets/img/logo.png'

const Registro = () => {
    const [Intento, setIntento] = useState(false)

    let navigate = useNavigate()

    function registrar() {
        const button = document.querySelector('#btnRegistro')
        const toast = document.querySelector('#toastRegistro')

        button.disabled = true
        setIntento(true)

        let url = "https://api.regalosplanetart.com.mx/Home/usr_register"
        
        const nameHTML  = document.querySelector("#registerName")
        const mailHTML  = document.querySelector("#registerEmail")
        const passHTML  = document.querySelector("#registerPass")
        const name      = nameHTML.value
        const mail      = mailHTML.value
        const pass      = passHTML.value

        // Si los valores no están vacío ejecuta la siguiente función
        if (name && mail && pass) {
            let data = new FormData()
                data.append('key', 'testKey')
                data.append('mail', mail)
                data.append('nombre', name)
                data.append('contra', pass)
    
            let xhr = new XMLHttpRequest()
                xhr.open('POST', url, true)
                xhr.onload = e => {
                    if (e.target.responseText === 'Correo invalido') {
                        button.disabled = false
                        setIntento(false)
    
                        nameHTML.classList.add('is-invalid')
                        mailHTML.classList.add('is-invalid')
                        passHTML.classList.add('is-invalid')
                    }

                    if (JSON.parse(e.target.responseText)[0] === 'Usuario insertado') {
                        setIntento(false)
                        button.disabled = false
                        toast.classList.remove('hide')
                        toast.classList.add('show')
                        
                        setTimeout(() => {
                            navigate('/iniciar-sesion')
                        }, 1000)
                    } else {
                        button.disabled = false
                        setIntento(false)
                        
                        nameHTML.classList.add('is-invalid')
                        mailHTML.classList.add('is-invalid')
                        passHTML.classList.add('is-invalid')
                    }
                    
                }
                xhr.send(data)
        } else {    // Hay valores vacíos o no válidos
            if (!name)
                nameHTML.classList.add('is-invalid')
            if (!mail)
                mailHTML.classList.add('is-invalid')
            if (!pass)
                passHTML.classList.add('is-invalid')
            
            button.disabled = false
            setIntento(false)
        }
    }

    function clearInput(e) {
        if (e.target.classList.contains('is-invalid'))
            e.target.classList.remove('is-invalid')
    }

    return (
        <div className="full-page d-flex align-items-center justify-content-between">
            <div className="container">
                <div className="shadow rounded p-3 mx-auto" style={{maxWidth: "600px"}}>
                    <div className="mb-3">
                        <label htmlFor="registerName" className="form-label">Nombre completo:</label>
                        <input type="text" name="registerName" id="registerName" className="form-control" onFocus={clearInput} />
                        <div className="invalid-feedback">
                            Hubo un error, por favor verifica los datos e intenta nuevamente.
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="registerEmail" className="form-label">Correo electrónico:</label>
                        <input type="email" name="registerEmail" id="registerEmail" className="form-control" onFocus={clearInput} />
                        <div className="invalid-feedback">
                            Hubo un error, por favor verifica los datos e intenta nuevamente.
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="registerPass" className="form-label">Contraseña:</label>
                        <input type="password" name="registerPass" id="registerPass" className="form-control" onFocus={clearInput} />
                        <div className="invalid-feedback">
                            Hubo un error, por favor verifica los datos e intenta nuevamente.
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <button className="btn bg-green text-white" id="btnRegistro" onClick={() => { registrar() }}>
                            {
                                Intento ?
                                <div className="text-center px-4">
                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> 
                                </div> :
                                'Inicar sesión'
                            }
                        </button>
                        <Link to="/iniciar-sesion">¿Ya tienes una cuenta? Iniciar sesión</Link>
                    </div>
                </div>
            </div>

            <div class="position-fixed bottom-0 end-0 p-3" style={{zIndex: '11'}}>
                <div id="toastRegistro" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                    <img src={LogoPA} class="rounded me-2" alt="Logo de Planet Art" style={{maxHeight: '25px'}} />
                    <small className="ms-auto">Justo ahora</small>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        Cuenta creada correctamente.
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Registro;