import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";

const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Exito = () => {
    let query = useQuery()

    useEffect(() => {
        completarCompra()
    })

    function completarCompra() {
        let url = 'https://api.regalosplanetart.com.mx/Home/compra_updateStatus'

        let data = new FormData()
            data.append('c', query.get('c'))
            data.append('status', 'Complete')

        let xhr = new XMLHttpRequest()
            xhr.open('POST', url, true)
            xhr.onload = response => {
                setTimeout(() => {
                    window.location = "https://regalosplanetart.com.mx"
                }, 3000)
            }
            xhr.send(data)
    }

    return (
        <div className="full-page">
            <div className="container my-5">
                <h1 className="text-center text-success">
                    Transacción realizada con éxito
                </h1>
                <p className="text-center">
                    En los próximos minutos le llegará un correo electrónico con la confirmación de su pago.
                </p>
            </div>
        </div>
    );
}
 
export default Exito;