import { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"

// Imágenes
import Img from "./../assets/img/menudeo/productos/01.png"
import LogoPA from './../assets/img/logo.png'

const Product = ({ name = 'cristodca'}) => {
    let { id } = useParams()

    const [nombre, setNombre]   = useState('')
    const [desc, setDesc]       = useState('')
    const [precio, setPrecio]   = useState(0)
    const [envio, setEnvio]     = useState(0)
    const [stock, setStock]     = useState([])
    const [image, setImage] = useState('')
    const [image2, setImage2] = useState('')
    const [image3, setImage3] = useState('')

    useEffect(() => {
        obtenerDetalles()
    }, [name])

    function obtenerDetalles() {
        let url = "https://api.regalosplanetart.com.mx/Home/getProdById"
        // let url = 'https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/getProdById'

        let data = new FormData()
            data.append('key', 'testKey')
            data.append('id', id)

        let xhr = new XMLHttpRequest()
            xhr.open('POST', url, true)
            xhr.onload = e => {
                const response = JSON.parse(e.target.responseText)
                const articulo = response.articulos[0]

                // console.log(articulo)

                setNombre(articulo.nombre)
                setDesc(articulo.descripcion)
                setPrecio(articulo.precio)
                setEnvio(articulo.precio_envio)
                setImage(articulo.imagen_1)
                setImage2(articulo.imagen_2)
                setImage3(articulo.imagen_3)

                let cantidadHTML = []

                for (let i = 1; i <= articulo.cantidad; i++) {
                    cantidadHTML.push(<option key={i} value={i}>{i}</option>)
                }

                setStock(cantidadHTML)
            }
            xhr.send(data)
    }

    function agregarCarrito() {
        let cantidad = document.querySelector('#productCantidad').value
        let orden = { id: parseInt(id), image: image, nombre: nombre, precio: precio, articulos: parseInt(cantidad), precio_envio: envio, image: image }
        
        let carrito = {}

        // El carrito ya existe
        if (localStorage.getItem('carrito')) {
            if (existeEnCarrito(parseInt(id)))  // El producto ya está en el carrito
                actualizarEnCarrito(orden)
            else
                agregarNuevoEnCarrito(orden)    // El producto NO está en el carrito
        } else {    // El carrito no existe
            carrito = {
                articulos: [orden]
            }

            localStorage.setItem('carrito', JSON.stringify(carrito))
            mostrarToast()
            setTimeout(() => {
                ocultarToast()
            }, 4000)
            
        }
    }

    function mostrarToast() {
        const toast = document.querySelector('#toastProduct')
        toast.classList.remove('hide')
        toast.classList.add('show')
    }

    function ocultarToast() {
        const toast = document.querySelector('#toastProduct')
        toast.classList.remove('show')
        toast.classList.add('hide')
    }

    function existeEnCarrito(idToSearch) {
        // Lee el carrito existente
        const carrito = JSON.parse(localStorage.getItem('carrito'))
        const articulos = carrito.articulos

        // Bandera para controlar el return de la función
        let existe = false

        // Si encuentra al menos un elemento con el ID a buscar, marca la bandera como verdadera
        articulos.forEach(e => {
            if (e.id === idToSearch)
                existe = true
        })
        
        // Si al menos un elemento coincidió (no debería ser más de uno) entonces retorna true.
        return existe
    }

    function agregarNuevoEnCarrito(orden) {
        // Lee el carrito existente
        let carrito = JSON.parse(localStorage.getItem('carrito'))
        let articulos = carrito.articulos

        // Agrega el nuevo elemento (se debió verificar previamente que no existiera ya).
        articulos.push(orden)

        // Actualiza el objeto en localStorage con la nueva orden agregada.
        localStorage.setItem('carrito', JSON.stringify({ articulos: articulos }))
        mostrarToast()
        setTimeout(() => {
            ocultarToast()
        }, 4000)
    }

    function actualizarEnCarrito(orden) {
        // console.log('El producto se va a actualizar')
        // Lee el carrito ya existente.
        const arr = JSON.parse(localStorage.getItem('carrito'))
        const carrito = arr.articulos
        // console.log(carrito);

        // Bandera para saber el índice del elemento ya existente que se busca actualizar.
        let indexProduct
        
        // Crea un nuevoCarrito con los elementos que NO coinciden con el ID y guarda el Index del que sí coincide.
        let nuevoCarrito = carrito.filter((articulo, index) => {
            if (articulo.id === orden.id)
                indexProduct = index

            return articulo.id !== orden.id
        })

        // console.log(nuevoCarrito);
        // console.log(indexProduct);
        // console.log(orden);
        // console.log(orden.articulos);
        // console.log(carrito[indexProduct].articulos);

        // Suma la cantidad que ya existía previamente con la que se quiere agregar.
        let nuevaCantidad = orden.articulos + carrito[indexProduct].articulos
        
        // Ya sumadas las cantidades, agrega el producto al array que contenía a los elementos distintos.
        nuevoCarrito.push({ id: orden.id, image: orden.image, nombre: orden.nombre, precio: orden.precio, articulos: nuevaCantidad })

        // Actualiza el localStorage con todos los productos y cantidades actualizadas.
        localStorage.setItem('carrito', JSON.stringify({ articulos: nuevoCarrito}))

        mostrarToast()
        setTimeout(() => {
            ocultarToast()
        }, 4000)
    }

    return (
        <div className="full-page d-flex align-items-center justify-content-center flex-column">
            <div className="container">
                <div className="row shadow rounded p-3 my-5">
                    <div className="col-12 col-md-6 p-2">
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                {
                                    image &&
                                    <div class="carousel-item active">
                                        <img src={`https://static.regalosplanetart.com.mx/assets/img/productos/${image}`} class="d-block w-100" alt="..."/>
                                    </div>
                                }
                                {
                                    image2 &&
                                    <div class="carousel-item">
                                        <img src={`https://static.regalosplanetart.com.mx/assets/img/productos/${image2}`} class="d-block w-100" alt="..."/>
                                    </div>
                                }
                                {
                                    image3 &&
                                    <div class="carousel-item">
                                        <img src={`https://static.regalosplanetart.com.mx/assets/img/productos/${image3}`} class="d-block w-100" alt="..."/>
                                    </div>
                                }
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-2 d-flex align-items-center">
                        <div>
                            <h3 className="mb-5">
                                { nombre }
                            </h3>
                            <p>
                                <b>Descripción del producto:</b> <br />
                                {desc}
                            </p>

                            <h5 className="fw-bold text-green mb-3">
                                $ {precio} MXN
                            </h5>

                            <div className="mb-3">
                                <label htmlFor="productCantidad" className="form-label fw-bold">
                                    Cantidad:
                                </label>
                                <select name="productCantidad" id="productCantidad" className="form-control">
                                    {stock}
                                </select>
                            </div>

                            <div className="mb-3">
                                <button className="btn text-white bg-green" onClick={() => { agregarCarrito() }}>
                                    Agregar al carrito
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="position-fixed bottom-0 end-0 p-3" style={{zIndex: '11'}}>
                <div id="toastProduct" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000" data-bs-autohide="true">
                    <div class="toast-header">
                    <img src={LogoPA} class="rounded me-2" alt="Logo de Planet Art" style={{maxHeight: '25px'}} />
                    <small className="ms-auto">Justo ahora</small>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        Producto agregado al carrito. <Link to="/carrito">Ver mi carrito.</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Product;