import { useState } from "react"
import { Link, useNavigate } from "react-router-dom";

// Imágenes
import LogoPA from './../assets/img/logo.png'

const Login = () => {
    const [intento, setIntento] = useState(false)

    const navigate = useNavigate()

    function iniciarSesion() {
        setIntento(true)
        const toast = document.querySelector('#toastLogin')
        const button = document.querySelector('#btnLogin')

        button.disabled = true

        let url = "https://api.regalosplanetart.com.mx/Home/usr_login"
        // let url = 'https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/usr_login'
        
        const mailHTML  = document.querySelector("#loginEmail")
        const passHTML  = document.querySelector("#loginPass")
        const mail      = mailHTML.value
        const pass      = passHTML.value

        // Si los valores no están vacío ejecuta la siguiente función
        if (mail && pass) {
            let data = new FormData()
                data.append('key', 'testKey')
                data.append('mail', mail)
                data.append('contra', pass)
    
            let xhr = new XMLHttpRequest()
                xhr.open('POST', url, true)
                xhr.onload = e => {
                    // console.log(e)
                    const response = JSON.parse(e.target.responseText)
                    
                    if (response && response[0] === 'Login exitoso') {

                        toast.classList.remove('hide')
                        toast.classList.add('show')

                        let expires = 60 * 60 * 24 * 1  // 1 día.
                        document.cookie = `userData=${encodeURIComponent(response[1])}; max-age=${expires}; path=/`

                        setIntento(false)
                        setTimeout(() => {
                            navigate('/')
                        }, 1000)
                    } else {
                        mailHTML.classList.add('is-invalid')
                        passHTML.classList.add('is-invalid')
                        setIntento(false)
                        button.disabled = false
                    }
                }
                xhr.send(data)
        } else {    // Hay valores vacíos o no válidos
            alert ('Por favor revisa que tus datos sean correctos.')
        }
    }

    function clearInput(e) {
        if (e.target.classList.contains('is-invalid'))
            e.target.classList.remove('is-invalid')
    }

    return (
        <div className="full-page d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="shadow px-3 py-4 mx-auto rounded" style={{maxWidth: "600px"}}>
                    <div className="mb-3">
                        <label htmlFor="loginEmail" className="form-label">Correo electrónico:</label>
                        <input type="email" name="loginEmail" id="loginEmail" className="form-control" onFocus={clearInput} />
                        <div className="invalid-feedback">
                            Hubo un error, por favor confirme sus datos e intente nuevamente.
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="loginPass" className="form-label">Contraseña:</label>
                        <input type="password" name="loginPass" id="loginPass" className="form-control"
                        onFocus={clearInput} />
                        <div className="invalid-feedback">
                            Hubo un error, por favor confirme sus datos e intente nuevamente.
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <button className="btn text-white fw-bold bg-green text-center" id="btnLogin" onClick={() => { iniciarSesion() }}>
                            {
                                intento ?
                                <div className="text-center px-4">
                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> 
                                </div> :
                                'Inicar sesión'
                            }
                        </button>
                        <Link to="/registro">¿Aun no tienes una cuenta? ¡Regístrate aquí!</Link>           
                    </div>
                </div>
            </div>

            <div class="position-fixed bottom-0 end-0 p-3" style={{zIndex: '11'}}>
                <div id="toastLogin" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                    <img src={LogoPA} class="rounded me-2" alt="Logo de Planet Art" style={{maxHeight: '25px'}} />
                    <small className="ms-auto">Justo ahora</small>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div class="toast-body">
                        Sesión iniciada correctamente
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Login;