import { useEffect, useState } from "react";
import axios from "axios";

const HomeSlider = () => {
  const [images, setImages] = useState({});

  const getSliderImages = () => {
    const queryData = new FormData();
    queryData.append("key", "testKey");

    axios
      .post("https://api.regalosplanetart.com.mx/Home/getSlid", queryData)
      .then((res) => {
        if (res.status === 200) {
          setImages(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSliderImages();
  });

  return (
    <>
      <div
        id="carouselExampleControls"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={`https://static.regalosplanetart.com.mx/assets/img/sliders/${images.slider_1}`}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src={`https://static.regalosplanetart.com.mx/assets/img/sliders/${images.slider_2}`}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src={`https://static.regalosplanetart.com.mx/assets/img/sliders/${images.slider_3}`}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src={`https://static.regalosplanetart.com.mx/assets/img/sliders/${images.slider_4}`}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src={`https://static.regalosplanetart.com.mx/assets/img/sliders/${images.slider_5}`}
              className="d-block w-100"
              alt="..."
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default HomeSlider;
