// Librerías
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
// CSS
import * as style from './../assets/css/cart.module.css'

const CartProduct = (props) => {
    const [cantidad, setCantidad] = useState(props.quantity)
    const [precio, setPrecio] = useState(props.price)
    let actualizar = props.actualizar
    const MAX_CANTIDAD = 10
    const MIN_CANTIDAD = 1

    function sumarCantidad(idToSearch) {
        let inputCantidad = document.querySelector(`#cantidad-${idToSearch}`)
        let cantidadAntigua = parseInt(inputCantidad.value)

        if (cantidadAntigua >= 1 && cantidadAntigua < MAX_CANTIDAD) {
            inputCantidad.value = cantidadAntigua + 1
            setCantidad(inputCantidad.value)
            actualizar('')
            actualizarCantidadEnCarrito(idToSearch, inputCantidad.value)
        }
    }
    
    function restarCantidad(idToSearch) {
        let inputCantidad = document.querySelector(`#cantidad-${idToSearch}`)
        let cantidadAntigua = parseInt(inputCantidad.value)

        if (cantidadAntigua > MIN_CANTIDAD) {
            inputCantidad.value = cantidadAntigua - 1
            setCantidad(inputCantidad.value)
            actualizar('')
            actualizarCantidadEnCarrito(idToSearch, inputCantidad.value)
        }
    }

    function actualizarCantidadEnCarrito(idProducto, nuevaCantidad) {
        let carrito = JSON.parse(localStorage.getItem('carrito'))
        let articulos = carrito.articulos
        let orden = {}

        let nuevoCarrito = articulos.filter((articulo) => {
            if (articulo.id === idProducto)
                orden = articulo

            return articulo.id !== idProducto
        })

        nuevoCarrito.push({ 
            id: orden.id, 
            nombre: orden.nombre, 
            precio: orden.precio, 
            articulos: nuevaCantidad, 
            precio_envio: orden.precio_envio 
        })

        localStorage.setItem('carrito', JSON.stringify({ articulos: nuevoCarrito} ))
    }
    
    function eliminarProducto(idToSearch) {
        let carrito = JSON.parse(localStorage.getItem('carrito'))
        let articulos = carrito.articulos

        let nuevoCarrito = articulos.filter((articulo) => {
            return articulo.id !== idToSearch
        })

        localStorage.setItem('carrito', JSON.stringify({ articulos: nuevoCarrito} ))
        actualizar('')
    }


    return (
        <div className="row border-bottom">
            <div className="col-12 col-md-8 d-flex py-3">
                <div>
                    <img src={`https://static.regalosplanetart.com.mx/assets/img/productos/${props.image}`} alt="Product Mini" className='img-fluid' style={{maxHeight: '100px'}}/>
                </div>
                <div className="ps-2">
                    <h5>{ props.title }</h5>
                    <small className="text-muted">Precio individual: $ { precio } MXN</small>
                </div>
            </div>
            <div className={`col-6 col-md-2 py-2 d-flex align-items-center justify-content-start justify-content-md-end ${ style.cartProductInputContainer }`}>
                <div className={`d-flex ms-2 justify-content-between align-items-center border rounded ${ style.btnMiniPlusMinus }`}>
                    <button type="button" className="btn" id={`restar-${props.id}`} onClick={() => {restarCantidad(props.id)}}>
                        <FontAwesomeIcon icon= { faMinus }></FontAwesomeIcon>
                    </button>
                    <span className="my-1">
                        <input type="number" name={`cantidad-${props.id}`} id={`cantidad-${props.id}`} defaultValue={props.quantity} className={`form-control border-0 text-center p-0 ${ style.quantityMiniButtonPlusMinus }`} />
                    </span>
                    <button type="button" className="btn" id={`sumar-${props.id}`} onClick={() => {sumarCantidad(props.id)}}>
                        <FontAwesomeIcon icon={ faPlus }></FontAwesomeIcon>
                    </button>
                </div> 
            </div>
            <div className="col-6 col-md-2 py-2 d-flex align-items-center justify-content-end justify-content-md-end">
                <p className="fs-5 fw-bold m-0">$ { precio * cantidad } MXN</p>
            </div>
            <div className="col-12 border-top py-1">
                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => {eliminarProducto(props.id)}}>
                    Eliminar
                </button>
            </div>
        </div>
    );
}
 
export default CartProduct;