import { 
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

import Navbar     from "./components/Navbar";
import Footer     from "./components/Footer";

import Home       from "./pages/Home";
import Mayoreo    from "./pages/Mayoreo";
import Menudeo    from "./pages/Menudeo";
import Login      from "./pages/Login";
import Registro   from "./pages/Registro";
import Product    from "./pages/Product";
import Productos  from "./pages/Productos";
import Carrito    from "./pages/Carrito";
import Pago       from "./pages/Pago";
import Exito      from "./pages/Exito";
import Fallo      from "./pages/Fallo";

function App() {
  return (
    <div className="App">
      <Router forceRefresh >

        <Navbar />

        <Routes> 
          <Route path="/"                     element={<Home/>} />
          <Route path="/mayoreo"              element={<Mayoreo/>} />
          <Route path="/menudeo"              element={<Menudeo/>} />
          <Route path="/iniciar-sesion"       element={<Login/>} />
          <Route path="/registro"             element={<Registro/>} />
          <Route path="/producto/:id"         element={<Product/>} />
          <Route path="/productos/:cat"       element={<Productos key={Date.now()}/>} />
          <Route path="/carrito"              element={<Carrito/>} />
          <Route path="/pago"                 element={<Pago/>} />
          <Route path="/transaccion/exitosa"  element={<Exito/>}/>
          <Route path="/transaccion/fallida"  element={<Fallo/>}/>
        </Routes>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
