import { Link }             from "react-router-dom";

// Imágenes
import HomeBanner           from "./../assets/img/home/banner/HomeBanner.png"
import MenudeoImage         from "./../assets/img/home/menudeo.png"
import MayoreoImage         from "./../assets/img/home/mayoreo.png"
import Cochito              from './../assets/img/home/COCHITO.jpg'
import ProductoDestacado01  from "./../assets/img/home/producto-destacado/01.png"
import ProductoDestacado02  from "./../assets/img/home/producto-destacado/02.png"
import ProductoDestacado03  from "./../assets/img/home/producto-destacado/03.png"
import ProductoDestacado04  from "./../assets/img/home/producto-destacado/04.png"
import ImgTransferencia     from './../assets/img/home/icons/transferencia-bancaria.png'
import ImgTarjeta           from './../assets/img/home/icons/tarjeta-de-credito.png'
import ImgTienda            from './../assets/img/home/icons/tienda.png'
import HomeSlider from "../components/HomeSlider";

const Home = () => {
    return (
        <div>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <HomeSlider />
                        {/* <img src={HomeBanner} alt="Planet Art" className="img-fluid w-100" /> */}
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center fw-bold mb-4">
                            ¡Bienvenido! ¿Qué estás buscando?
                        </h2>

                        <div className="d-flex align-items-center justify-content-evenly flex-column flex-md-row">
                            <Link to="/menudeo" style={{maxHeight: "500px", maxWidth: "500px"}}>
                                <div className="p-2">
                                    <img src={MenudeoImage} alt="Menudeo" className="img-fluid" />
                                </div>
                            </Link>
                            <Link to="/mayoreo" style={{maxHeight: "500px", maxWidth: "500px"}}>
                                <div className="p-2">
                                    <img src={MayoreoImage} alt="Mayoreo" className="img-fluid" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row bg-green">
                    <div className="col-12 py-5 px-3">
                        <h3 className="text-center fw-bold text-light mb-5">
                            Métodos de pago
                        </h3>

                        <div className="d-flex align-items-center justify-content-evenly flex-column flex-md-row">
                            <div className="text-center p-2">
                                <img src={ImgTransferencia} alt="Transferencia" className="img-fluid w-25" />
                                <h5 className="fw-bold text-light mt-3">
                                    Transferencia
                                </h5>
                            </div>
                            <div className="text-center p-2">
                                <img src={ImgTarjeta} alt="Pago con tarjeta" className="img-fluid w-25" />
                                <h5 className="fw-bold text-light mt-3">
                                    Tarjeta de crédito o débito
                                </h5>
                            </div>
                            <div className="text-center p-2">
                                <img src={ImgTienda} alt="Pago en oxxo" className="img-fluid w-25" />
                                <h5 className="fw-bold text-light mt-3">
                                    OXXO
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-5">
                <div className="row">
                    <div className="col-12">
                        <img src={Cochito} alt="Quienes somos" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Home;