import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_51K4UBlFwMOF06fOJ3cffoHFmwr8BcCTXx0Tz4uozELTcnCcfL6o1k2fUvxHUvKDGRBYuORhU8VAiDHvZZ240auIy00juUAxrDE')

const Pago = () => {
    const options = {
        clientSecret: 'cs_test_b1bwzxhO4gOPyZuJ4rZvUfqWU5d7O2Gnolse5bER2UfNOm9OG3hcuYXsXs'
    }

    return (
        <Elements stripe={stripePromise} options={options}>
            {/* <CheckoutForm /> */}
        </Elements>
    );
}
 
export default Pago;