// Componentes
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CartProduct from "../components/CartProduct";

// Imágenes
import baseProductMini from "./../assets/img/mayoreo/productos/productMini-1.png";

const Carrito = ({ carritoLocal = localStorage.getItem("carrito") }) => {
  const navigate = useNavigate();
  // Estados iniciales
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [precio, setPrecio] = useState({
    subtotal: 0.0,
    extras: 0.0,
    total: 0.0,
  });
  const [actualizacion, setActualizacion] = useState(false);
  const [isLoged, setIsLoged] = useState(true);

  useEffect(() => {
    obtenerCarrito();
  }, [actualizacion]); // [carritoLocal] indica que es una variable necesaria, y mientras no cambie, sólo se ejecutará una vez.

  function actualizarHijo(arg) {
    setActualizacion(!actualizacion);
  }

  function obtenerCarrito() {
    // Obtiene el carrito local y lo convierte en JSON
    let carrito = JSON.parse(carritoLocal);
    let articulos = carrito.articulos;

    // Variables de apoyo para calcular totales
    let carritoTemporal = [];
    let subtotal = 0.0;
    let extras = 0.0;

    // Por cada elemento del carrito, crea un objeto HTML, además de sumar a la variable de subtotal para llevar la cuenta.
    articulos.forEach((articulo) => {
      carritoTemporal.push(
        <CartProduct
          key={articulo.id}
          actualizar={actualizarHijo}
          id={articulo.id}
          title={articulo.nombre}
          description=""
          price={articulo.precio}
          quantity={parseInt(articulo.articulos)}
          image={articulo.image}
        />
      );

      subtotal += articulo.precio * articulo.articulos;
      extras += articulo.precio_envio;
    });

    // Objeto temporal que se igualará al estado
    let precioTemporal = {
      subtotal: subtotal,
      extras: extras,
      total: subtotal + extras,
    };

    // Actualización de estado
    setProductosCarrito(carritoTemporal);
    setPrecio(precioTemporal);
  }

  function pagar() {
    let USR_ID;
    const url = "https://api.regalosplanetart.com.mx/Home/decodeUserData";
    // const url = "https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/decodeUserData"

    let data = new FormData();
    data.append("jwt", getCookie("userData"));

    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onload = (e) => {
      USR_ID = JSON.parse(e.target.responseText).id;

      // console.log('id usuario: ', USR_ID)
      if (USR_ID) {
        setIsLoged(true);
        enviarDatosDeCompra(USR_ID);
        xhr.abort();
      } else {
        setIsLoged(false);
        alert("Es necesario iniciar sesión para poder realizar el pago.");
        navigate("/iniciar-sesion");
      }
    };
    xhr.send(data);
  }

  function enviarDatosDeCompra(usrId) {
    const url = "https://api.regalosplanetart.com.mx/Home/compras_newOrder";
    // const url = "https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/compras_newOrder"

    let method = "CARD";
    let userCarrito = JSON.parse(localStorage.getItem("carrito")).articulos;
    let userCorreo = document.querySelector("#carritoEmail").value;
    let userNombre = document.querySelector("#carritoName").value;
    let userCalle = document.querySelector("#carritoNE").value;
    let userNE = document.querySelector("#carritoCalle").value;
    let userCP = document.querySelector("#carritoCP").value;
		let factName = document.querySelector('#factName').value
		let factEmail = document.querySelector('#factEmail').value
		let factRFC = document.querySelector('#factRFC').value
		let factRS = document.querySelector('#factRS').value
		let factCalle = document.querySelector('#factCalle').value
		let factNE = document.querySelector('#factNE').value
		let factNI = document.querySelector('#factNI').value
		let factPais = document.querySelector('#factPais').value
		let factEstado = document.querySelector('#factEstado').value
		let factMunicipio = document.querySelector('#factMunicipio').value
		let factColonia = document.querySelector('#factColonia').value
		let factCP = document.querySelector('#factCP').value
    let userCarritoNoParse = localStorage.getItem("carrito");

    if (userCorreo && userNombre && userCalle && userNE && userCP) {
      let data2 = new FormData();
      data2.append("key", "testKey");
      data2.append("usuario", `${usrId}`);
      data2.append("email", `${userCorreo}`);
      data2.append("articulos", `${JSON.stringify(userCarrito)}`);
      data2.append(
        "factura",
        `{"nombre" : "${factName}","email" : "${factEmail}","rfc" : "${factRFC}","rs" : "${factRS}","calle" : "${factCalle}","ne" : "${factNE}","ni" : "${factNI}","colonia" : "${factColonia}","cp" : "${factCP}","municipio" : "${factMunicipio}","estado" : "${factEstado}","pais" : "${factPais}"}`
      );
      data2.append(
        "sending",
        `{"nombre" : "${userNombre}","email" : "${userCorreo}","rfc" : "","rs" : "","calle" : "${userCalle}","ne" : "${userNE}","ni" : "","colonia" : "","cp" : "${userCP}","municipio" : "","estado" : "","pais" : ""}`
      );
      data2.append("comm", "Sin comentarios");
      data2.append("method", "CARD");
      data2.append("postal", `${userCP}`);

      // console.log(data2)

      let xhr2 = new XMLHttpRequest();
      xhr2.open("POST", url, true);
      xhr2.onload = (response) => {
        // console.log(response)
        const responseJSON = JSON.parse(response.target.responseText);
        // console.log(responseJSON)

        window.location = responseJSON.url;
      };
      xhr2.send(data2);
    } else {
      alert(
        "Es necesario llenar todos los campos para poder realizar tu envío"
      );
    }
  }

  // Función para obtener el valor de una cookie
  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <div className="full-page">
      <div className="container py-5">
        <div className="border rounded p-md-4 p-sm-2 p-1 overflow-hidden cartCard">
          {/* <CartProgressBar products="4" freeShippingMin="24000" freeShippingCurrent="23400" ></CartProgressBar> */}
          {productosCarrito}
          <div className="row border-bottom py-4">
            <div className="col-8 col-md-8 col-lg-4 offset-0 offset-md-2 offset-lg-6 text-end">
              <p className="fs-5 text-muted">Subtotal</p>
            </div>
            <div className="col-4 col-md-2 text-end">
              <p className="fs-4 text-muted">
                ${" "}
                {new Intl.NumberFormat("es-MX", {
                  minimumFractionDigits: 2,
                }).format(precio.subtotal)}{" "}
                MXN
              </p>
            </div>

            <div className="col-8 col-md-8 col-lg-4 offset-0 offset-md-2 offset-lg-6 text-end pe-3">
              <p className="fs-5 text-muted">Envío</p>
            </div>
            <div className="col-4 col-md-2 text-end">
              <p className="fs-4 text-muted">
                ${" "}
                {new Intl.NumberFormat("es-MX", {
                  minimumFractionDigits: 2,
                }).format(precio.extras)}{" "}
                MXN
              </p>
            </div>

            <div className="col-8 col-md-8 col-lg-4 offset-0 offset-md-2 offset-lg-6 text-end">
              <p className="fs-4">Total</p>
            </div>
            <div className="col-4 col-md-2 text-end">
              <p className="fs-3 fw-bold">
                ${" "}
                {new Intl.NumberFormat("es-MX", {
                  minimumFractionDigits: 2,
                }).format(precio.total)}{" "}
                MXN
              </p>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-12 text-end">
              <button
                className="btn btn-success btn-lg"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#sendingModal"
              >
                Continuar
              </button>
            </div>
          </div>
        </div>

        {/* Modal datos */}
        <div
          className="modal fade"
          id="sendingModal"
          tabIndex="-1"
          aria-labelledby="sendingModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="sendingModalLabel">
                  Información de envío
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h5>Datos de envío</h5>
                <div className="form-group mb-2">
                  <label htmlFor="carritoEmail" className="form-label">
                    Correo electrónico:
                  </label>
                  <input
                    type="email"
                    name="carritoEmail"
                    id="carritoEmail"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="carritoName" className="form-label">
                    Nombre completo:{" "}
                  </label>
                  <input
                    type="text"
                    name="carritoName"
                    id="carritoName"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="carritoCalle" className="form-label">
                    Calle:{" "}
                  </label>
                  <input
                    type="text"
                    name="carritoCalle"
                    id="carritoCalle"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="carritoNE" className="form-label">
                    Número exterior:{" "}
                  </label>
                  <input
                    type="number"
                    name="carritoNE"
                    id="carritoNE"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="carritoCP" className="form-label">
                    Código Postal:{" "}
                  </label>
                  <input
                    type="number"
                    name="carritoCP"
                    id="carritoCP"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  className="btn btn-outline-success"
                  data-bs-target="#factModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Factura
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    pagar();
                  }}
                >
                  Proceder al pago
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal facturación */}
        <div
          className="modal fade"
          id="factModal"
          tabIndex="-1"
          aria-labelledby="factModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="factModalLabel">
                  Información de facturación
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h5>Datos para facturación</h5>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="factName" className="form-label">
                        Nombre completo:{" "}
                      </label>
                      <input
                        type="text"
                        name="factName"
                        id="factName"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="factEmail" className="form-label">
                        Correo electrónico:
                      </label>
                      <input
                        type="email"
                        name="factEmail"
                        id="factEmail"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pe-2">
                    <div className="form-group mb-2">
                      <label htmlFor="factRFC" className="form-label">
                        RFC:
                      </label>
                      <input
                        type="text"
                        name="factRFC"
                        id="factRFC"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-2">
                      <label htmlFor="factRS" className="form-label">
                        Razón social:
                      </label>
                      <input
                        type="text"
                        name="factRS"
                        id="factRS"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="factCalle" className="form-label">
                        Calle:{" "}
                      </label>
                      <input
                        type="text"
                        name="factCalle"
                        id="factCalle"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pe-2">
                    <div className="form-group mb-2">
                      <label htmlFor="factNE" className="form-label">
                        Número exterior:{" "}
                      </label>
                      <input
                        type="number"
                        name="factNE"
                        id="factNE"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-2">
                      <label htmlFor="factNI" className="form-label">
                        Número interior:{" "}
                      </label>
                      <input
                        type="number"
                        name="factNI"
                        id="factNI"
                        className="form-control"
                      />
                    </div>
                  </div>
									<div className="col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="factPais" className="form-label">
												País:
											</label>
                      <input
                        type="text"
                        name="factPais"
                        id="factPais"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="factEstado" className="form-label">
												Estado:
											</label>
                      <input
                        type="text"
                        name="factEstado"
                        id="factEstado"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="factMunicipio" className="form-label">
												Municipio:
											</label>
                      <input
                        type="text"
                        name="factMunicipio"
                        id="factMunicipio"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-8 pe-2">
                    <div className="form-group mb-2">
                      <label htmlFor="factColonia" className="form-label">
                        Colonia:
                      </label>
                      <input
                        type="text"
                        name="factColonia"
                        id="factColonia"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group mb-2">
                      <label htmlFor="factCP" className="form-label">
                        Código Postal:{" "}
                      </label>
                      <input
                        type="number"
                        name="factCP"
                        id="factCP"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    pagar();
                  }}
                >
                  Proceder al pago
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrito;
