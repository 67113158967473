import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-green py-5">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <div className="social text-white mb-3 mb-md-0">
                        <a href="https://api.whatsapp.com/send/?phone=3334968365&text=Quiero+m%C3%A1s+informaci%C3%B3n+de+los+productos+PlanetArt&app_absent=0" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-reset">
                            <FontAwesomeIcon icon={faWhatsapp} size="3x" className="me-3" />
                        </a>
                        <a href="https://www.facebook.com/regalosplanetart/" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-reset">
                            <FontAwesomeIcon icon={faFacebookF} size="3x" />
                        </a>
                    </div>

                    <div className="text-white d-flex align-items-center justify-content-center flex-column flex-md-row">
                        {/* <Link to="/" className="text-white ms-md-3 p-2 p-md-0 text-decoration-none">
                            Preguntas frecuentes
                        </Link> */}
                        <a href="https://static.regalosplanetart.com.mx/docs/Aviso-de-Privacidad-PlanetArt.pdf" target='_blank' rel="noopener noreferrer" className="text-white ms-md-3 p-2 p-md-0 text-decoration-none">
                            Aviso de privacidad
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
 
export default Footer;