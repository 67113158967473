import { Link } from 'react-router-dom';

const CardProduct = (props) => {
    return (
        <div className="col-6 col-md-4 col-lg-3 p-2 h-100">
            <Link className="text-decoration-none text-reset h-100" to={`/producto/${props.id}`}>
            <div className="shadow rounded h-100">
                <div className='ratio ratio-1x1'>
                    <img src={`https://static.regalosplanetart.com.mx/assets/img/productos/${props.image}`} alt={props.title} className="img-fluid" />
                </div>
                <div className="p-3">
                    <h5 className='truncateOneLineText'>
                        {props.title}
                    </h5>
                    <p className='truncateOneLineText'>
                        {props.desc}
                    </p>
                </div>
            </div>
            </Link>
        </div>
    );
}
 
export default CardProduct;