const Fallo = () => {
    return (
        <div className="full-page">
            <div className="container my-5">
                <h1 className="text-center text-danger">
                    Error en la transacción
                </h1>
                <p className="text-center">
                    Por favor revise sus datos e intente nuevamente. Si cree que se trata de un error por favor contacte a soporte@regalosplanetart.com.mx
                </p>
            </div>
        </div>
    );
}
 
export default Fallo;